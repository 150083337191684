import React from 'react'

const className = 'font-sansSerif text-p2 antialiased md:text-p2md'
export const P2ClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  as?: 'div' | 'span' | 'a' | 'button'
  href?: string
  small?: boolean
}

const P2: React.FC<Props> = ({
  small,
  children,
  className,
  as = 'p',
  ...other
}) => {
  const Component = as as any
  return (
    <Component
      className={
        P2ClassName +
        (className ? ` ${className}` : '') +
        (small ? ' !text-p3 md:!text-p4md' : '')
      }
      {...other}
    >
      {children}
    </Component>
  )
}

export default P2
